import { createAction, props } from '@ngrx/store';

import { StripeCustomer } from '../../models';

export const loadRequest = createAction(
  '[Stripe Customer] Load Request',
  props<{ customerId: number; propertyId: number }>(),
);

export const loadSuccess = createAction(
  '[Stripe Customer] Load Success',
  props<{ stripeCustomer: StripeCustomer }>(),
);

export const loadFailure = createAction(
  '[Stripe Customer] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Stripe Customer] Reset State');
