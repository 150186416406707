import { createFeatureSelector, createSelector } from '@ngrx/store';

import { StripeCustomer } from '../../models';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getData = (state: State): StripeCustomer => state.stripeCustomer;

export const selectState = createFeatureSelector<State>('stripeCustomer');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectData = createSelector(selectState, getData);
