import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { StripeCustomer } from '../../models';

export interface State {
  stripeCustomer: StripeCustomer;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  stripeCustomer: null,
  isLoading: false,
  error: null,
};
