import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { StripeCustomer } from '../../models';
import { StripeCustomerService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class StripeCustomerStoreEffects {
  constructor(
    private dataService: StripeCustomerService,
    private actions$: Actions,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ customerId, propertyId }) =>
        this.dataService.load(customerId, propertyId).pipe(
          map((response: IResponseSuccess<StripeCustomer>) =>
            fromActions.loadSuccess({ stripeCustomer: response.data }),
          ),
          catchError((error) => of(fromActions.loadFailure(error))),
        ),
      ),
    ),
  );
}
